<template>
  <div class="page m-page">
    <div class="main">
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/supplier/title@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="normal-image unselectable"
      />
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/supplier/advantage@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="normal-image unselectable"
      />
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/supplier/pattern@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="full-width-image unselectable"
      />
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/supplier/procedure@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="full-width-image unselectable"
      />
        <img
          src="https://directimg.miaocang.cc/mpImg/attraction/supplier/type_title@2x.png"
          draggable="false"
          oncontextmenu="return false;"
          class="title-image"
        />
      <!-- 招商品种区域: 由于招商品种会不定期更新, 所以内容要在H5页面内写. -->
      <div class="card">
        <div class="type">
          <div class="label">地被</div>
          <div class="value">黄金叶、麦冬、银边山管兰、黄金叶、麦冬、银边山管兰黄金叶、麦冬、银边山管兰、黄金叶、麦冬、银边山管兰</div>
        </div>
        <div class="type">
          <div class="label">时花</div>
          <div class="value">黄金叶、麦冬、银边山管兰、黄金叶、麦冬、银边山管兰、黄金叶、麦冬、银边山管兰</div>
        </div>
        <div class="type">
          <div class="label">观赏草</div>
          <div class="value">黄金叶、麦冬、银边山管兰、黄金叶、麦冬、银边山管兰黄金叶、麦冬、银边山管兰</div>
        </div>
      </div>
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/supplier/requirement@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="full-width-image"
      />

      <div class="text">
        <div @click="toSupplierCooperationPage">附件：《苗仓地被直采中心供应商合作协议》</div>
        <div>咨询热线：400-838-6166</div>
      </div>
    </div>
    <div v-if="isNeedLogin === 1" class="footer m-footer">
      <van-button class="half-button" color="#999" plain @click="toApplyPage">申请入驻</van-button>
      <van-button class="half-button" color="#00ae66" @click="toWxLoginPage">登录</van-button>
    </div>
    <div v-else class="footer m-footer">
      <van-button class="large-button" size="large" color="#00ae66" @click="toApplyPage">立即报名</van-button>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'

export default {
  name: 'SupplierAttractionLogin',

  data() {
    return {
      // 底部是否需要显示登录按钮
      isNeedLogin: 1
    }
  },

  methods: {
    /* 跳转到供应商入驻合作协议页 */
    toSupplierCooperationPage() {
      this.$router.push({
        name: 'SupplierCooperation'
      })
    },

    /* 申请入驻的点击事件处理(跳转到华南项目的申请入驻H5页) */
    toApplyPage() {
      window.open('https://sc.miaocang.cc/#/mBusiness')
      // wx.miniProgram.navigateTo({
      //   url: '/pages/webView/webView?src=https://sc.miaocang.cc/#/mBusiness'
      // })
    },
    
    /* 登录按钮的点击事件处理(跳转小程序内的登录页) */
    toWxLoginPage() {
      wx.miniProgram.navigateTo({
        url: '/pages/auth/login/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  background-color: #00ae66;
  .main {
    text-align: center;
    padding-bottom: 112px;
    img {
      object-fit: cover;
    }
    .normal-image {
      width: 80%;
      margin: 28px 0;
    }
    .title-image {
      width: 68%;
      z-index: 1000;
    }
    .full-width-image {
      width: 100%;
    }

    .card {
      width: 82%;
      margin: -40px auto 40px;
      padding: 40px 27px 33px 27px;
      text-align: left;
      background: #F7FFFC;
      box-shadow: inset 0px 2px 3px 0px rgba(255,255,255,0.5000);
      border-radius: 8px;
      .type {
        &:not(&:last-child) {
          margin-bottom: 10px;
        }
        .label {
          height: 36px;
          line-height: 36px;
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }
        .value {
          line-height: 24px;
          font-size: 16px;
        }
      }
    }
    .text {
      padding: 0 14px;
      text-align: left;
      font-size: 14px;
      color: #fff;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-top: 14px;
    text-align: center;
    background-color: #fff;
    z-index: 1000;
    .half-button {
      width: 43.6%;
      &:first-child {
        margin-right: 14px;
      }
    }
    .large-button {
      width: 92%;
    }
  }
}
</style>